import { a as alchemyRpcUrls, i as infuraRpcUrls, d as defaultAlchemyId } from './rpcs-7cfbd91c.esm.js';

const etherscanBlockExplorers = {
  mainnet: {
    name: 'Etherscan',
    url: 'https://etherscan.io'
  },
  ropsten: {
    name: 'Etherscan',
    url: 'https://ropsten.etherscan.io'
  },
  rinkeby: {
    name: 'Etherscan',
    url: 'https://rinkeby.etherscan.io'
  },
  goerli: {
    name: 'Etherscan',
    url: 'https://goerli.etherscan.io'
  },
  kovan: {
    name: 'Etherscan',
    url: 'https://kovan.etherscan.io'
  },
  optimism: {
    name: 'Etherscan',
    url: 'https://optimistic.etherscan.io'
  },
  optimismKovan: {
    name: 'Etherscan',
    url: 'https://kovan-optimistic.etherscan.io'
  },
  polygon: {
    name: 'PolygonScan',
    url: 'https://polygonscan.com'
  },
  polygonMumbai: {
    name: 'PolygonScan',
    url: 'https://mumbai.polygonscan.com'
  },
  arbitrum: {
    name: 'Arbiscan',
    url: 'https://arbiscan.io'
  },
  arbitrumRinkeby: {
    name: 'Arbiscan',
    url: 'https://testnet.arbiscan.io'
  }
};

const chainId = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
  optimism: 10,
  optimismKovan: 69,
  polygon: 137,
  polygonMumbai: 80001,
  arbitrum: 42161,
  arbitrumRinkeby: 421611,
  localhost: 1337,
  hardhat: 31337
};
const mainnet = {
  id: chainId.mainnet,
  name: 'Ethereum',
  network: 'homestead',
  ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.mainnet,
    infura: infuraRpcUrls.mainnet,
    default: "".concat(alchemyRpcUrls.mainnet, "/").concat(defaultAlchemyId)
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.mainnet,
    default: etherscanBlockExplorers.mainnet
  }
};
const ropsten = {
  id: chainId.ropsten,
  name: 'Ropsten',
  network: 'ropsten',
  ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  nativeCurrency: {
    name: 'Ropsten Ether',
    symbol: 'ropETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.ropsten,
    infura: infuraRpcUrls.ropsten,
    default: "".concat(alchemyRpcUrls.ropsten, "/").concat(defaultAlchemyId)
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.ropsten,
    default: etherscanBlockExplorers.ropsten
  },
  testnet: true
};
const rinkeby = {
  id: chainId.rinkeby,
  name: 'Rinkeby',
  network: 'rinkeby',
  ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  nativeCurrency: {
    name: 'Rinkeby Ether',
    symbol: 'rETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.rinkeby,
    infura: infuraRpcUrls.rinkeby,
    default: "".concat(alchemyRpcUrls.rinkeby, "/").concat(defaultAlchemyId)
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.rinkeby,
    default: etherscanBlockExplorers.rinkeby
  },
  testnet: true
};
const goerli = {
  id: chainId.goerli,
  name: 'Goerli',
  network: 'goerli',
  ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  nativeCurrency: {
    name: 'Goerli Ether',
    symbol: 'gETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.goerli,
    infura: infuraRpcUrls.goerli,
    default: "".concat(alchemyRpcUrls.goerli, "/").concat(defaultAlchemyId)
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.goerli,
    default: etherscanBlockExplorers.goerli
  },
  testnet: true
};
const kovan = {
  id: chainId.kovan,
  name: 'Kovan',
  network: 'kovan',
  nativeCurrency: {
    name: 'Kovan Ether',
    symbol: 'kETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.kovan,
    infura: infuraRpcUrls.kovan,
    default: "".concat(alchemyRpcUrls.kovan, "/").concat(defaultAlchemyId)
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.kovan,
    default: etherscanBlockExplorers.kovan
  },
  testnet: true
};
const optimism = {
  id: chainId.optimism,
  name: 'Optimism',
  network: 'optimism',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.optimism,
    infura: infuraRpcUrls.optimism,
    default: 'https://mainnet.optimism.io'
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.optimism,
    default: etherscanBlockExplorers.optimism
  }
};
const optimismKovan = {
  id: chainId.optimismKovan,
  name: 'Optimism Kovan',
  network: 'optimism-kovan',
  nativeCurrency: {
    name: 'Kovan Ether',
    symbol: 'KOR',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.optimismKovan,
    infura: infuraRpcUrls.optimismKovan,
    default: 'https://kovan.optimism.io'
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.optimismKovan,
    default: etherscanBlockExplorers.optimismKovan
  },
  testnet: true
};
const polygon = {
  id: chainId.polygon,
  name: 'Polygon',
  network: 'matic',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.polygon,
    infura: infuraRpcUrls.polygon,
    default: 'https://polygon-rpc.com'
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.polygon,
    default: etherscanBlockExplorers.polygon
  }
};
const polygonMumbai = {
  id: chainId.polygonMumbai,
  name: 'Polygon Mumbai',
  network: 'maticmum',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.polygonMumbai,
    infura: infuraRpcUrls.polygonMumbai,
    default: 'https://matic-mumbai.chainstacklabs.com'
  },
  blockExplorers: {
    etherscan: etherscanBlockExplorers.polygonMumbai,
    default: etherscanBlockExplorers.polygonMumbai
  },
  testnet: true
};
const arbitrum = {
  id: chainId.arbitrum,
  name: 'Arbitrum',
  network: 'arbitrum',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'AETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.arbitrum,
    infura: infuraRpcUrls.arbitrum,
    default: 'https://arb1.arbitrum.io/rpc'
  },
  blockExplorers: {
    arbitrum: {
      name: 'Arbitrum Explorer',
      url: 'https://explorer.arbitrum.io'
    },
    etherscan: etherscanBlockExplorers.arbitrum,
    default: etherscanBlockExplorers.arbitrum
  }
};
const arbitrumRinkeby = {
  id: chainId.arbitrumRinkeby,
  name: 'Arbitrum Rinkeby',
  network: 'arbitrum-rinkeby',
  nativeCurrency: {
    name: 'Arbitrum Rinkeby Ether',
    symbol: 'ARETH',
    decimals: 18
  },
  rpcUrls: {
    alchemy: alchemyRpcUrls.arbitrumRinkeby,
    infura: infuraRpcUrls.arbitrumRinkeby,
    default: 'https://rinkeby.arbitrum.io/rpc'
  },
  blockExplorers: {
    arbitrum: {
      name: 'Arbitrum Explorer',
      url: 'https://rinkeby-explorer.arbitrum.io'
    },
    etherscan: etherscanBlockExplorers.arbitrumRinkeby,
    default: etherscanBlockExplorers.arbitrumRinkeby
  },
  testnet: true
};
const localhost = {
  id: chainId.localhost,
  name: 'Localhost',
  network: 'localhost',
  rpcUrls: {
    default: 'http://127.0.0.1:8545'
  }
};
const hardhat = {
  id: chainId.hardhat,
  name: 'Hardhat',
  network: 'hardhat',
  rpcUrls: {
    default: 'http://127.0.0.1:8545'
  }
};
/**
 * Common chains for convenience
 * Should not contain all possible chains
 */

const chain = {
  mainnet,
  ropsten,
  rinkeby,
  goerli,
  kovan,
  optimism,
  optimismKovan,
  polygon,
  polygonMumbai,
  arbitrum,
  arbitrumRinkeby,
  localhost,
  hardhat
};
const allChains = Object.values(chain);
const defaultChains = [chain.mainnet, chain.ropsten, chain.rinkeby, chain.goerli, chain.kovan];
const defaultL2Chains = [chain.arbitrum, chain.arbitrumRinkeby, chain.optimism, chain.optimismKovan];

export { allChains as a, chainId as b, chain as c, defaultChains as d, defaultL2Chains as e, etherscanBlockExplorers as f, arbitrum as g, arbitrumRinkeby as h, goerli as i, hardhat as j, kovan as k, localhost as l, mainnet as m, optimismKovan as n, optimism as o, polygon as p, polygonMumbai as q, rinkeby as r, ropsten as s };
