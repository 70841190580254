"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.useMutation = useMutation;

var _react = _interopRequireDefault(require("react"));

var _useSyncExternalStore = require("./useSyncExternalStore");

var _core = require("../core");

var _utils = require("../core/utils");

var _mutationObserver = require("../core/mutationObserver");

var _QueryClientProvider = require("./QueryClientProvider");

var _utils2 = require("./utils");

function useMutation(arg1, arg2, arg3) {
  const options = (0, _utils.parseMutationArgs)(arg1, arg2, arg3);
  const queryClient = (0, _QueryClientProvider.useQueryClient)({
    context: options.context
  });

  const [observer] = _react.default.useState(() => new _mutationObserver.MutationObserver(queryClient, options));

  _react.default.useEffect(() => {
    observer.setOptions(options);
  }, [observer, options]);

  const result = (0, _useSyncExternalStore.useSyncExternalStore)(_react.default.useCallback(onStoreChange => observer.subscribe(_core.notifyManager.batchCalls(onStoreChange)), [observer]), () => observer.getCurrentResult(), () => observer.getCurrentResult());

  const mutate = _react.default.useCallback((variables, mutateOptions) => {
    observer.mutate(variables, mutateOptions).catch(_utils.noop);
  }, [observer]);

  if (result.error && (0, _utils2.shouldThrowError)(observer.options.useErrorBoundary, [result.error])) {
    throw result.error;
  }

  return { ...result,
    mutate,
    mutateAsync: result.mutate
  };
}