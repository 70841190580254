"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.useIsFetching = useIsFetching;

var _react = _interopRequireDefault(require("react"));

var _useSyncExternalStore = require("./useSyncExternalStore");

var _core = require("../core");

var _utils = require("../core/utils");

var _QueryClientProvider = require("./QueryClientProvider");

function useIsFetching(arg1, arg2, arg3) {
  const [filters, options = {}] = (0, _utils.parseFilterArgs)(arg1, arg2, arg3);
  const queryClient = (0, _QueryClientProvider.useQueryClient)({
    context: options.context
  });
  const queryCache = queryClient.getQueryCache();
  return (0, _useSyncExternalStore.useSyncExternalStore)(_react.default.useCallback(onStoreChange => queryCache.subscribe(_core.notifyManager.batchCalls(onStoreChange)), [queryCache]), () => queryClient.isFetching(filters), () => queryClient.isFetching(filters));
}