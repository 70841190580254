"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.useIsMutating = useIsMutating;

var _react = _interopRequireDefault(require("react"));

var _useSyncExternalStore = require("./useSyncExternalStore");

var _notifyManager = require("../core/notifyManager");

var _utils = require("../core/utils");

var _QueryClientProvider = require("./QueryClientProvider");

function useIsMutating(arg1, arg2, arg3) {
  const [filters, options = {}] = (0, _utils.parseMutationFilterArgs)(arg1, arg2, arg3);
  const queryClient = (0, _QueryClientProvider.useQueryClient)({
    context: options.context
  });
  const queryCache = queryClient.getQueryCache();
  return (0, _useSyncExternalStore.useSyncExternalStore)(_react.default.useCallback(onStoreChange => queryCache.subscribe(_notifyManager.notifyManager.batchCalls(onStoreChange)), [queryCache]), () => queryClient.isMutating(filters), () => queryClient.isMutating(filters));
}