import EventEmitter from 'eventemitter3';
import { d as defaultChains } from './chains-541a65cc.esm.js';

function _checkPrivateRedeclaration(obj, privateCollection) {
  if (privateCollection.has(obj)) {
    throw new TypeError("Cannot initialize the same private elements twice on an object");
  }
}

function _classPrivateMethodInitSpec(obj, privateSet) {
  _checkPrivateRedeclaration(obj, privateSet);
  privateSet.add(obj);
}

function _classPrivateFieldInitSpec(obj, privateMap, value) {
  _checkPrivateRedeclaration(obj, privateMap);
  privateMap.set(obj, value);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classApplyDescriptorGet(receiver, descriptor) {
  if (descriptor.get) {
    return descriptor.get.call(receiver);
  }

  return descriptor.value;
}

function _classExtractFieldDescriptor(receiver, privateMap, action) {
  if (!privateMap.has(receiver)) {
    throw new TypeError("attempted to " + action + " private field on non-instance");
  }

  return privateMap.get(receiver);
}

function _classPrivateFieldGet(receiver, privateMap) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get");
  return _classApplyDescriptorGet(receiver, descriptor);
}

function _classPrivateMethodGet(receiver, privateSet, fn) {
  if (!privateSet.has(receiver)) {
    throw new TypeError("attempted to get private field on non-instance");
  }

  return fn;
}

function _classApplyDescriptorSet(receiver, descriptor, value) {
  if (descriptor.set) {
    descriptor.set.call(receiver, value);
  } else {
    if (!descriptor.writable) {
      throw new TypeError("attempted to set read only private field");
    }

    descriptor.value = value;
  }
}

function _classPrivateFieldSet(receiver, privateMap, value) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "set");
  _classApplyDescriptorSet(receiver, descriptor, value);
  return value;
}

function normalizeChainId(chainId) {
  if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10);
  return chainId;
}

/**
 * Error subclass implementing JSON RPC 2.0 errors and Ethereum RPC errors per EIP-1474.
 * @see https://eips.ethereum.org/EIPS/eip-1474
 */
class RpcError extends Error {
  constructor(
  /** Number error code */
  code,
  /** Human-readable string */
  message,
  /** Low-level error */
  internal,
  /** Other useful information about error */
  data) {
    if (!Number.isInteger(code)) throw new Error('"code" must be an integer.');
    if (!message || typeof message !== 'string') throw new Error('"message" must be a nonempty string.');
    super(message);

    _defineProperty(this, "code", void 0);

    _defineProperty(this, "data", void 0);

    _defineProperty(this, "internal", void 0);

    this.code = code;
    this.data = data;
    this.internal = internal;
  }

}
/**
 * Error subclass implementing Ethereum Provider errors per EIP-1193.
 * @see https://eips.ethereum.org/EIPS/eip-1193
 */

class ProviderRpcError extends RpcError {
  /**
   * Create an Ethereum Provider JSON-RPC error.
   * `code` must be an integer in the 1000 <= 4999 range.
   */
  constructor(
  /**
   * Number error code
   * @see https://eips.ethereum.org/EIPS/eip-1193#error-standards
   */
  code,
  /** Human-readable string */
  message,
  /** Low-level error */
  internal,
  /** Other useful information about error */
  data) {
    if (!(Number.isInteger(code) && code >= 1000 && code <= 4999)) throw new Error('"code" must be an integer such that: 1000 <= code <= 4999');
    super(code, message, internal, data);
  }

}
class AddChainError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'AddChainError');

    _defineProperty(this, "message", 'Error adding chain');
  }

}
class ChainNotConfiguredError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ChainNotConfigured');

    _defineProperty(this, "message", 'Chain not configured');
  }

}
class ConnectorAlreadyConnectedError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ConnectorAlreadyConnectedError');

    _defineProperty(this, "message", 'Connector already connected');
  }

}
class ConnectorNotFoundError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ConnectorNotFoundError');

    _defineProperty(this, "message", 'Connector not found');
  }

}
class SwitchChainError extends ProviderRpcError {
  constructor(error) {
    super(4902, 'Error switching chain', error);

    _defineProperty(this, "name", 'SwitchChainError');
  }

}
class SwitchChainNotSupportedError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'SwitchChainNotSupportedError');

    _defineProperty(this, "message", 'Switch chain not supported by connector');
  }

}
class UserRejectedRequestError extends ProviderRpcError {
  constructor(error) {
    super(4001, 'User rejected request', error);

    _defineProperty(this, "name", 'UserRejectedRequestError');
  }

}
class ResourceUnavailableError extends RpcError {
  constructor(error) {
    super(-32002, 'Resource unavailable', error);

    _defineProperty(this, "name", 'ResourceUnavailable');
  }

}

class Connector extends EventEmitter {
  /** Unique connector id */

  /** Connector name */

  /** Chains connector supports */

  /** Options to use with connector */

  /** Whether connector is usable */
  constructor(_ref) {
    let {
      chains = defaultChains,
      options
    } = _ref;
    super();

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "name", void 0);

    _defineProperty(this, "chains", void 0);

    _defineProperty(this, "options", void 0);

    _defineProperty(this, "ready", void 0);

    this.chains = chains;
    this.options = options;
  }

  getBlockExplorerUrls(chain) {
    var _chain$blockExplorers;

    const {
      default: blockExplorer,
      ...blockExplorers
    } = (_chain$blockExplorers = chain.blockExplorers) !== null && _chain$blockExplorers !== void 0 ? _chain$blockExplorers : {};
    if (blockExplorer) return [blockExplorer.url, ...Object.values(blockExplorers).map(x => x.url)];
    return [];
  }

  isChainUnsupported(chainId) {
    return !this.chains.some(x => x.id === chainId);
  }

}

export { AddChainError as A, ConnectorAlreadyConnectedError as C, ProviderRpcError as P, ResourceUnavailableError as R, SwitchChainNotSupportedError as S, UserRejectedRequestError as U, _classPrivateMethodInitSpec as _, ConnectorNotFoundError as a, Connector as b, ChainNotConfiguredError as c, RpcError as d, SwitchChainError as e, _defineProperty as f, _classPrivateFieldInitSpec as g, _classPrivateFieldGet as h, _classPrivateFieldSet as i, _classPrivateMethodGet as j, normalizeChainId as n };
