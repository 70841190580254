"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.useSyncExternalStore = void 0;

var _index = _interopRequireDefault(require("use-sync-external-store/shim/index.js"));

const useSyncExternalStore = _index.default.useSyncExternalStore;
exports.useSyncExternalStore = useSyncExternalStore;