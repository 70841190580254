"use strict";

exports.__esModule = true;

var _persist = require("./persist");

Object.keys(_persist).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _persist[key]) return;
  exports[key] = _persist[key];
});

var _PersistQueryClientProvider = require("./PersistQueryClientProvider");

Object.keys(_PersistQueryClientProvider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PersistQueryClientProvider[key]) return;
  exports[key] = _PersistQueryClientProvider[key];
});

var _retryStrategies = require("./retryStrategies");

Object.keys(_retryStrategies).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _retryStrategies[key]) return;
  exports[key] = _retryStrategies[key];
});